import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import Button from '../button';
import ReCAPTCHA from "react-google-recaptcha";
import DateTime from './fields/dateTime';
import Time from './fields/time';

const required = value => value ? undefined : "Required"


class Text extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="text-field">
                <label>{this.props.label}</label>
                <input type={this.props.type} placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} className={this.props.error ? 'input-field required' : 'input-field'} />
            </div>

        );
    }
}
class Textarea extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <textarea placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} className={this.props.error ? 'input-field required' : 'input-field'} ></textarea>
        );
    }
}

class Captcha extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ReCAPTCHA
                sitekey="6Ld2TrcUAAAAAK6kDP_6yfWTiR4iRnpsP7iEXuMC"
                onChange={this.props.onChange}
            />
        );
    }
}


const renderDateTimeField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    busyDays
}) => (

        <DateTime
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            busyDays={busyDays}
            {...input}
        />
    )

const renderTimeField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    addError
}) => (

        <Time
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            addError={addError}

            {...input}
        />
    )


const renderTextField = ({
    input,
    placeholder,
    type,
    label,
    meta: { touched, error },
}) => (

        <Text
            label={label}
            placeholder={placeholder}
            type={type}
            error={touched && error}

            {...input}
        />
    )

const renderTextareaField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            error={touched && error}

            {...input}
        />
    )
const renderCaptchaField = ({
    input,
    meta: { touched, error },
}) => (

        <Captcha
            {...input}
        />
    )




class reserveForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.date != this.props.date || prevProps.from != this.props.from || prevProps.to != this.props.to && this.props.date && this.props.date.year && this.props.date.month && this.props.date.day) {

            let data = {
                date: this.props.date,
                from: this.props.from,
                to: this.props.to
            }

            if (data.from && data.from.hour && data.from.minute)
                data.tsFrom = Math.floor(new Date(data.date.year, data.date.month - 1, data.date.day, data.from.hour, data.from.minute, 0, 0).getTime() / 1000);
            if (data.to && data.to.hour && data.to.minute)
                data.tsTo = Math.floor(new Date(data.date.year, data.date.month - 1, data.date.day, data.to.hour, data.to.minute, 0, 0).getTime() / 1000);

            fetch('https://api.boostercoaching.ch/check', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }).then((res) => res.json()).then((result) => {
                if (result.tsTo && data.to && data.to.hour && data.to.minute) {
                    this.setState({ toError: false })
                } else if (data.to && data.to.hour && data.to.minute && !result.tsTo) {
                    this.setState({ toError: true })

                }

                if (result.tsFrom && data.from && data.from.hour && data.from.minute) {
                    this.setState({ fromError: false })
                }
                else if (data.from && data.from.hour && data.from.minute && !result.tsFrom) {
                    this.setState({ fromError: true })

                }

            })

        }
    }

    render() {
        const { handleSubmit, pristine, reset, submitting } = this.props;
        //console.log(pristine, submitting);
        console.log(this.props.date)
        return (
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col lg="6">
                        <Field name="name" type="text" placeholder="Nom" label="Nom" component={renderTextField} validate={[required]} />
                    </Col>
                    <Col lg="6">
                        <Field name="email" type="email" placeholder="E-mail" label="E-mail" component={renderTextField} validate={[required]} />

                    </Col>
                    <Col lg="12">
                        <Field name="phone" type="text" placeholder="Téléphone" label="Téléphone" component={renderTextField} validate={[required]} />
                    </Col>
                    <Col lg="6">
                        <Field name="date" label="Date" component={renderDateTimeField} busyDays={this.props.busyDays} validate={[required]} />

                    </Col>
                    <Col lg="3" xs="6">
                        <Field name="from" label="De" component={renderTimeField} validate={[required]} addError={this.state.fromError} />

                    </Col>
                    <Col lg="3" xs="6">
                        <Field name="to" label="À" component={renderTimeField} validate={[required]} addError={this.state.toError}/>

                    </Col>

                    <Col lg="8">
                        <Field name="token" component={renderCaptchaField} validate={[required]} />
                    </Col>
                    <Col lg="4">
                        <Button additionalClassName="submit">ENVOYER</Button>
                    </Col>
                </Row>
            </form>
        )
    }
}



reserveForm = reduxForm({
    form: 'reserveForm' // a unique identifier for this form
})(reserveForm)

// Decorate with connect to read form values
const selector = formValueSelector('reserveForm') // <-- same as form name
reserveForm = connect(state => {
    return {
        from: selector(state, 'from'),
        to: selector(state, 'to'),
        date: selector(state, 'date')
    }
})(reserveForm)

export default reserveForm
