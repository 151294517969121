
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';

import Isvg from 'react-inlinesvg';
import moment from 'moment';

import calendarIcon from '../../../assets/calendar.svg';

class DateTime extends Component {
    constructor(props) {
        super(props);

        this.state = {
            day: null,
            month: null,
            year: null,
            hour: null,
            minute: null
        };
    }


    render() {


        let value = this.props.value ? this.props.value : { hour: '', minute: '' };


        return (
            <>
                {this.props.label ? <label>{this.props.label}</label> : null}
                <div className={`date-time-picker-wrap ${this.props.addError === true ? "time-error" :  this.props.addError === false ? "time-correct" : "" }`}>

                    <div className="time-input">
                        <input value={value.hour} onChange={(e) => {
                            let val = this.props.value;
                            if (!val) {
                                val = { hour: '', minute: '' };
                            }
                            //console.log(e.target.value)
                            val.hour = e.target.value;
                            this.props.onChange(val);
                            this.forceUpdate();
                        }} type="text" placeholder="HH" className="digit2" ref={(node) => this.hour = node}
                            onKeyDown={(e) => {
                                if (("0123456789".indexOf(e.key) === -1) && e.keyCode != 8 && e.keyCode != 13) {
                                    e.preventDefault();
                                    return false;
                                }
                            }}

                            onKeyUp={(e) => {
                                if (e.target.value.length >= 2) {
                                    this.hour.blur();
                                    this.minute.focus();
                                } else if (e.target.value.length == 0) {

                                }
                            }} />
                        <span>:</span>
                        <input value={value.minute} onChange={(e) => {
                            let val = this.props.value;
                            if (!val) {
                                val = { hour: '', minute: '' };
                            }
                           // console.log(e.target.value)
                            val.minute = e.target.value;
                            this.props.onChange(val);
                            this.forceUpdate();
                        }} type="text" placeholder="mm" className="digit2" ref={(node) => this.minute = node}
                            onKeyDown={(e) => {
                                if (("0123456789".indexOf(e.key) === -1) && e.keyCode != 8 && e.keyCode != 13) {
                                    e.preventDefault();
                                    return false;
                                }
                            }}

                            onKeyUp={(e) => {
                                if (e.target.value.length >= 2) {
                                    this.minute.blur();
                                } else if (e.target.value.length == 0) {
                                    this.minute.blur();
                                    this.hour.focus();
                                }
                            }} />
                        <div className="buttons-wrap">
                            <button type="button" className="inc" onClick={() => {
                                let val = this.props.value;
                                if (!val) {
                                    val = { hour: '00', minute: '00' };
                                }

                                //console.log(value);

                                let minutes = val.hour * 60 + parseInt(val.minute);

                                minutes += 30;
                                if (minutes <= 1440) {
                                    this.props.onChange({
                                        hour: ('0' + Math.floor(minutes / 60).toString()).slice(-2),
                                        minute: ('0' + Math.floor((minutes) % 60).toString()).slice(-2)
                                    })
                                }


                            }}></button>
                            <button type="button" className="dec" onClick={() => {
                                let val = this.props.value;
                                if (!val) {
                                    val = { hour: '00', minute: '00' };
                                }

                               // console.log(value);

                                let minutes = val.hour * 60 + parseInt(val.minute);

                                minutes -= 30;
                                if (minutes >= 0) {
                                    this.props.onChange({
                                        hour: ('0' + Math.floor(minutes / 60).toString()).slice(-2),
                                        minute: ('0' + Math.floor((minutes) % 60).toString()).slice(-2)
                                    })
                                }


                            }}></button>
                        </div>
                    </div>

                </div>
            </>


        );
    }
}

export default DateTime;