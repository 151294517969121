import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header'
import Footer from '../components/footer'

import left1 from '../assets/anims/left/1.png';
import left2 from '../assets/anims/left/2.png';
import left3 from '../assets/anims/left/3.png';
import left4 from '../assets/anims/left/4.png';
import left5 from '../assets/anims/left/5.png';
import left6 from '../assets/anims/left/6.png';
import left7 from '../assets/anims/left/7.png';
import left8 from '../assets/anims/left/8.png';

import right1 from '../assets/anims/right/1.png';
import right2 from '../assets/anims/right/2.png';
import right3 from '../assets/anims/right/3.png';
import right4 from '../assets/anims/right/4.png';
import right5 from '../assets/anims/right/5.png';
import right6 from '../assets/anims/right/6.png';
import right7 from '../assets/anims/right/7.png';
import right8 from '../assets/anims/right/8.png';
import right9 from '../assets/anims/right/9.png';


export const DefaultLayout = (Wrapped) => (props) => {
    return (
        <div className="wrapper">
            <img src={left1} className="animation-1" />
            <img src={left2} className="animation-2" />
            <img src={left3} className="animation-3" />
            <img src={left4} className="animation-4" />
            <img src={left5} className="animation-5" />
            <img src={left6} className="animation-6" />
            <img src={left7} className="animation-7" />
            <img src={left8} className="animation-8" />


            <img src={right1} className="right-animation-1" />
            <img src={right2} className="right-animation-2" />
            <img src={right3} className="right-animation-3" />
            <img src={right4} className="right-animation-4" />
            <img src={right5} className="right-animation-5" />
            <img src={right6} className="right-animation-6" />
            <img src={right7} className="right-animation-7" />
            <img src={right8} className="right-animation-8" />
            <img src={right9} className="right-animation-9" />
            <img src={right9} className="right-animation-10" />
            <img src={right3} className="right-animation-11" />
             <Header {...props} />
            <Wrapped {...props} />
            <Footer {...props} />



        </div>
    );
};

export default DefaultLayout;