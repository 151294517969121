import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { GoogleMapScript } from "./components/googleMapScript";
import HomePage from "./views/homePage";
import BlogPage from "./views/blogPage";
import BlogDetailPage from "./views/blogDetailPage";


class Routes extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    /*const unlisten = history.listen((location, action) => {
            this.props.handleMenu(null);
        });
        */

        fetch('https://api.boostercoaching.ch/pagesWeb', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((res) => res.json()).then((result) => {
          this.setState({
            webPages: result
          })
        })
  }

  render() {
    return (
      <Router>
        <>
          <GoogleMapScript API_KEY="AIzaSyDx7uNRz2GYWKLlAlfT6wugFOSBXQ7EZaQ" />

          <Switch className="react-switch">
            <Route
              path="/blog"
              exact
              render={(...renderProps) => (
                <BlogPage {...renderProps} {...this.props} />
              )}
            />

            <Route
              path="/blog/:alias/:id"
              exact
              render={(...renderProps) => (
                <BlogDetailPage {...renderProps} {...this.props} />
              )}
            />

            <Route
              path="/temoignages"
              exact
              render={(...renderProps) => (
                <HomePage {...renderProps} {...this.props} webPages={this.state.webPages}/>
              )}
            />
            {this.props.routeNames.map((item, idx) => {
              if (item.isPage)
                return (
                  <Route
                    path={item.link}
                    exact
                    render={(...renderProps) => (
                      <HomePage {...renderProps} {...this.props} webPages={this.state.webPages}/>
                    )}
                  />
                );
            })}

            <Route
              path="/contact"
              exact
              render={(...renderProps) => (
                <HomePage {...renderProps} {...this.props} />
              )}
            />
            <Route
              path="/page/:alias"
              exact
              render={(...renderProps) => (
                <BlogDetailPage {...renderProps} {...this.props} webPages={this.state.webPages}/>
              )}
            />
            
        
          </Switch>
        </>
      </Router>
    );
  }
}

export default Routes;
