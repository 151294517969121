import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Isvg from "react-inlinesvg";
import Page from "../containers/page";
import DocumentMeta from "react-document-meta";

import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

import anim1 from "../assets/first.gif";
import anim2 from "../assets/anim2.png";
import anim3 from "../assets/meta.gif";
import anim4 from "../assets/5.png";
import anim5 from "../assets/laptop.gif";
import leftImg from "../assets/left.png";
import rightImg from "../assets/right.png";
import user from "../assets/user2.png";
import acc from "../assets/acc.png";

import scroll from "../assets/scroll.svg";

import left from "../assets/left.svg";
import right from "../assets/right.svg";
import Button from "../components/button";
import ReCAPTCHA from "react-google-recaptcha";

import Form from "../components/forms/form";

import "whatwg-fetch";
import ReserveForm from "../components/forms/reserveForm";

//site key
//6Ld2TrcUAAAAAK6kDP_6yfWTiR4iRnpsP7iEXuMC

//secret key
//6Ld2TrcUAAAAALfqnwx3jd74A77tOqPhLKyzjel_

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = { activeIndex: 0, yScroll: 0, webPages2: [] };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.contact = this.contact.bind(this);
    this.reserve = this.reserve.bind(this);

    this.nodes = [null, null, null, null, null, null];
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.reviews.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.reviews.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
    window.addEventListener("touchmove", this.listenToScroll, false);

    if (this.props[0].location.pathname !== "/") {
      setTimeout(() => {
        this.scrollTo(true);
      }, 500);
    }

    fetch('https://api.boostercoaching.ch/pagesWeb', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json()).then((result) => {
      this.setState({
        webPages2: result
      })
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
    window.removeEventListener("touchmove", this.listenToScroll);
  }

  listenToScroll = () => {
    const yScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (this.state._scrolling) {
      return;
    }

    let dirrection;
    if (yScroll > this.state.yScroll) {
      dirrection = 0;
    } else {
      dirrection = 1;
    }

    let _routes = JSON.parse(JSON.stringify(this.props._routes));
    _routes.splice(5, 1);

    //if (dirrection == 0) {
    for (let i = this.nodes.length - 1; i >= 0; i--) {
    //was 160 110 on both down
      if (
        yScroll >
        this.nodes[i].offsetTop - (window.innerWidth > 1023 ? 160 : 110) && 
        yScroll <
        this.nodes[i].offsetTop +
        this.nodes[i].clientHeight -
        (window.innerWidth > 1023 ? 160 : 110)
      ) {
        
        if (_routes[i] != this.props[0].location.pathname) {
          this.props.enableScrollIntoView(null, () => {
            this.props[0].history.push(_routes[i]);
              
          });
        }

        break;
      }
    }

    this.setState({
      yScroll,
    });
  };

  componentDidUpdate(prevProps) {

    if (
      prevProps[0].location.pathname !== this.props[0].location.pathname &&
      this.props._enableScrollIntoView
    ) {
      this.setState(
        {
          _scrolled: null,
        },
        () => {
          this.scrollTo();
        }
      );
    }
  }

  async scrollTo(noAnim = false) {

    this.setState({
      _scrolled: true,
    });

    let pathname = this.props[0].location.pathname;

    if (this.props.routes[pathname] === null) {
      return;
    }

    let node = this.nodes[
      pathname == "/contact" ? 5 : this.props.routes[pathname]
    ];
    if (!node) {
      return;
    }



    if (noAnim) {
      window.scrollTo({
        top: node.offsetTop - (window.innerWidth > 1023 ? -120 : -110),
        left: 0,
      });
    } else {
      window.scrollTo({
        top: node.offsetTop - (window.innerWidth > 1023 ? -120 : -110),
        left: 0,
        behavior: "smooth",
      });
    }

    this.setState(
      {
        _scrolling: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            _scrolling: null,
          });
        }, 1000);
      }
    );

    /*
                if (routes[pathname] == 0){
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                }else{
                    node.scrollIntoView({behavior: 'smooth'});
                }*/
  }

  contact(data) {
    //console.log(data);

    fetch("https://https://api.boostercoaching.ch/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.successful) {
          this.setState({
            _contactDone: true,
          });
        }
      });
  }

  reserve(data) {
    //console.log(data);

    data.tsFrom = Math.floor(
      new Date(
        data.date.year,
        data.date.month - 1,
        data.date.day,
        data.from.hour,
        data.from.minute,
        0,
        0
      ).getTime() / 1000
    );
    data.tsTo = Math.floor(
      new Date(
        data.date.year,
        data.date.month - 1,
        data.date.day,
        data.to.hour,
        data.to.minute,
        0,
        0
      ).getTime() / 1000
    );

    fetch("https://api.boostercoaching.ch/reserve", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.successful) {
          this.setState({
            _reserveDone: true,
          });
        } else {
          this.setState({
            _reserveError: result.error,
          });
        }
      });
  }

  render() {
    let pageName;
    for (let i = 0; i < this.props.routeNames.length; i++) {
      if (this.props[0].location.pathname == this.props.routeNames[i].link) {
        pageName = this.props.routeNames[i].name;
      }
    }

    const meta = {
      title: pageName + " | " + this.props.seo.title,
      description: this.props.seo.description,
      meta: {
        charset: "utf-8",
        property: {
          keywords: this.props.seo.tags,
          "og:url": window.location.href,
          "og:title": pageName + " | " + this.props.seo.title,
          "og:image": this.props.seo.image,
          "og:description": this.props.seo.description,
          keywords: this.props.seo.keywords,
        },
      },
    };

    ////console.log(this.nodes);
    const { activeIndex } = this.state;

    const slides = this.props.reviews.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.title}
        >
          <h6>TEMOIGNAGES</h6>
          <h3>{item.name}</h3>
          <p dangerouslySetInnerHTML={{ __html: item.content }} />
        </CarouselItem>
      );
    });

    return <div className="page-wrap">
      <DocumentMeta {...meta} />

      <section className="section-1" ref={(node) => (this.nodes[0] = node)}>
        <Container>
          <Row>
            <Col lg={{ size: 6, order: 1 }} md={{ size: 6, order: 0 }} xs={{ size: 12, order: 1 }} dangerouslySetInnerHTML={{ __html: this.props.pages[0] && this.props.pages[0].content }} />
            <Col lg={{ size: 6, order: 1 }} md={{ size: 6, order: 1 }} xs={{ size: 12, order: 0 }}>
              {/*<div className="animation-container">
                                    <Lottie options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: animationData1,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }}></Lottie>
                                </div>*/}
              <img src={anim1} />
            </Col>
            <Col lg={{ size: 12, order: 2 }} className="scroll-down" md={{ size: 12, order: 2 }} xs={{ size: 12, order: 2 }} onClick={() => this.props.enableScrollIntoView(true)}>
              <Link to="/qui-suis-je">
                {/* <Isvg src={scroll} />
                                    <span>DÉFILEMENT EN BAS</span> */}
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section-2" ref={(node) => (this.nodes[1] = node)}>
        <Container>
          <Row>
            <Col lg={{ size: 6, order: 0 }} md={{ size: 6, order: 0 }} xs={{ size: 12, order: 1 }}>
              {/* <img src={anim2} /> */}
              <div className="contact-image">
                <img className="contact-image" src={user} />
                <img className="contact-image" src={acc} />
              </div>
            </Col>

            <Col lg={{ size: 6, order: 1 }} md={{ size: 6, order: 1 }} xs={{ size: 12, order: 0 }} dangerouslySetInnerHTML={{ __html: this.props.pages[1] && this.props.pages[1].content }} />
          </Row>
          <div ref={(node) => (this.nodes[2] = node)}>
            {/* <Row>
                <Col
                  lg={{ size: 6, order: 2 }}
                  md={{ size: 6, order: 2 }}
                  xs={{ size: 12, order: 2 }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.pages[2] && this.props.pages[2].content,
                  }}
                />
                <Col
                  lg={{ size: 6, order: 3 }}
                  md={{ size: 6, order: 3 }}
                  xs={{ size: 12, order: 3 }}
                >

                  <img src={anim3} />
                </Col>
              </Row> */}
          </div>
        </Container>
      </section>

      <section className="section-3">
        <Container>
          <Row dangerouslySetInnerHTML={{ __html: this.props.pages[2] && this.props.pages[2].bottomContent }} />
        </Container>
      </section>

      <section className="section-4" ref={(node) => (this.nodes[3] = node)}>
        <Container>
          {/* _________________________________________ NOVO POLJE _________________________________________  */}

          <h3>PRESTATIONS:</h3>

          <Row>
           
            {
             this.state.webPages2 && this.state.webPages2.map(
              (page, idx) => {
              
                return (
                  <Col sm="6" md="4">
                    <Link to={`/page/${page.name}`}>
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front">
                            <p>{page.name}</p>
                            {/* <p>{page.alias.replaceAll("-", " ").replace("/", "")}</p> */}
                          </div>
                          <div className="flip-card-back">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: page.bottomContent,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>

                );
              }
            )}
            <Col lg="12">
              <div className="buttons-class">
                <Button>
                  <Link onClick={() => this.props.enableScrollIntoView(true)} to="/contact">
                    {" "}
                    J’APPELLE POUR EN SAVOIR PLUS
                  </Link>
                </Button>
                <Button additionalClassName="reserve-button" onClick={() => this.setState(
                  { reserveModal: true }
                )}>
                  JE RÉSERVE MA SÉANCE
                </Button>
              </div>
            </Col>
          </Row>

          {/* _________________________________________ /NOVO POLJE _________________________________________  */}

          {/* <Row>
              <h3>PRESTATIONS:</h3>

              <Col xl="12" lg="12" md={{ size: 12 }} xs="12">


                <div className="prestations-circles-wrapper">
                  <div className="prestations-circles-row">
                    {this.props.webPages && this.props.webPages.map(
                        (page, idx) => {
                          if (idx < 3) {
                            return (
                              <Link to={`/page/${page.name}`}>
                                <div class="flip-card">
                                  <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                      <p>{page.name}</p>
                                          </div>
                                    <div class="flip-card-back">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: page.bottomContent,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                          }
                        }
                      )}
                  </div>

                  <div className="prestations-circles-row">
                    {this.props.webPages && this.props.webPages.map(
                        (page, idx) => {
                          if (idx >= 3) {
                            return (
                              <Link to={`/page/${page.name}`}>
                                <div class="flip-card">
                                  <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                      <p>{page.name}</p>
                                          </div>
                                    <div class="flip-card-back">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: page.bottomContent,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                          }
                        }
                      )}
                  </div>
                </div>
                </Col>
              <Col lg="12">
                <div className="buttons-class">
                  <Link onClick={() => this.props.enableScrollIntoView(true)} to="/contact">
                    {" "}
                    <Button>J’APPELLE POUR EN SAVOIR PLUS</Button>
                  </Link>
                  <Button additionalClassName="reserve-button" onClick={() => this.setState(
                        { reserveModal: true }
                      )}>
                    JE RÉSERVE MA SÉANCE
                  </Button>
                </div>
              </Col>
            </Row> */}
        </Container>
      </section>

      <section className="section-5" ref={(node) => (this.nodes[4] = node)}>
        <img src={leftImg} className="bg" />
        <Container>
          <Row>
            <Col lg="4" md="4" xs="12">
              {/* <div className="animation-container">
                                    <Lottie options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: animationData5,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }}></Lottie>
                                </div>*/}
              <img src={anim4} className="image" />
            </Col>
            <Col lg={{ offset: 1, size: 6 }} md="7" xs="12">
              <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} interval={5000}>
                {slides}
              </Carousel>

              <button onClick={this.previous}>
                <Isvg src={left} />
              </button>
              <button onClick={this.next}>
                <Isvg src={right} />
              </button>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section-6" ref={(node) => (this.nodes[5] = node)}>
        <img src={rightImg} className="bg" />
        <Container>
          <Row>
            <Col lg="4" md={{ size: 4 }}>
              <h3>PRENDRE RENDEZ-VOUS</h3>
              <h6>Adresse:</h6>
              <p dangerouslySetInnerHTML={{ __html: this.props.data.address && this.props.data.address.replace(/\n/g, "<br/>") }} />
              <h6>E-mail:</h6>
              <p>
                <a href={`mailto://${this.props.data.email}`}>
                  {this.props.data.email}
                </a>
              </p>
              <h6>Téléphone:</h6>
              <p>
                <a href={`tel://${this.props.data.phone}`}>
                  {this.props.data.phone}
                </a>
              </p>
              {/* <div className="contact-image">
                  <img className="contact-image" src={user} />
                  <img className="contact-image" src={acc} />
                </div> */}
            </Col>
            <Col lg={{ offset: 2, size: 6 }} md={{ size: 8 }}>
              <Container>
                <Row>
                  <Col lg="12">
                    <h2>CONTACTEZ-NOUS</h2>
                  </Col>
                </Row>

                <Form onSubmit={this.contact} />
                {this.state._contactDone ? <Row>
                  <Col lg="12">
                    <p>Votre message a bien été transmis</p>
                  </Col>
                </Row> : null}
              </Container>
            </Col>
          </Row>
        </Container>
      </section>
      {this.state.reserveModal ? <div className="modal-overlay">
        <div className="modal-wrap">
          <Container>
            <Row>
              <Col lg="12">
                <h2>{/* Je réserve ma séance d’essai */}</h2>
                <button className="close-btn" onClick={() => this.setState(
                  {
                    reserveModal: null,
                    _reserveDone: null,
                    _reserveError: null,
                  }
                )} />
              </Col>
            </Row>

            {!this.state._reserveDone ? <>
              <ReserveForm busyDays={this.props.busyDays} onSubmit={this.reserve} />
              <p className="error">{this.state._reserveError}</p>
            </> : <div className="done">
              <div />
              <h3>Votre demande a bien été envoyée</h3>
            </div>}
          </Container>
        </div>
      </div> : null}
    </div>;
  }
}

export default Page(HomePage);
