import React, { Component } from "react";
import Isvg from "react-inlinesvg";
import { Link } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import phone from "../assets/phone.svg";
import fb from "../assets/fb.svg";
import instagram from "../assets/instagram.svg";

import pin from "../assets/pin.png";
import { OSM, Vector as VectorSource } from "ol/source";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";

import Marker from "ol-marker-feature";
import Popup from "ol-popup";
import { createStringXY } from "ol/coordinate";
import { useGeographic } from "ol/proj";

import { Point } from "ol/geom";
const routes = [
  {
    name: "ACCUEIL",
    link: "",
  },
  {
    name: "QUI SUIS-JE",
    link: "/qui-suis-je",
  },
  {
    name: "QUE FAIT-ON CONCRETEMENT ?",
    link: "/que-fait-on-concretement",
  },
  {
    name: "TEMOIGNAGES",
    link: "/temoignages",
  },
  {
    name: "TARIFS ET PRESTATIONS",
    link: "/tarifs-et-prestations",
  },
  {
    name: "BLOG",
    link: "/blog",
  },

  {
    name: "CONTACT",
    link: "/contact",
  },
];

export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  initMap() {
    //console.log("InitMAP");
    // this.setState({
    //     _mapInit: true
    // });
    // var latLng = new window.google.maps.LatLng(this.props.data.gps.split(',')[0], this.props.data.gps.split(',')[1]
    // );
    // var map = new window.google.maps.Map(this.GoogleMap, {
    //     zoom: 16,
    //     center: latLng,
    //     mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    //     disableDefaultUI: true,
    //     gestureHandling: "gestureHandling",
    // });
    // var marker = new window.google.maps.Marker({
    //     position: latLng,
    //     map: map,
    //     icon: pin
    // });
    // marker.addListener('click', () => {
    //     var win = window.open('https://www.google.com/maps/place/Booster+Coaching/@46.4486258,6.8954574,19z/data=!3m1!4b1!4m5!3m4!1s0x478e9b558c549d89:0xef4369751144360e!8m2!3d46.4486258!4d6.8960046', '_blank');
    //     win.focus();
    // });
    // this.setState({ googleMap: map });
  }

  componentDidMount() {
    // if (this.props._googleMapsLoaded && !this.state._mapInit && this.props.data.gps) {
    //     this.initMap();
    // }

    const mapFunction = () => {

      const coordinates = [46.44867318803371, 6.896111629585334];

      var degrees2meters = function(lon, lat) {
        var x = (lon * 20037508.34) / 180;
        var y =
          Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
        y = (y * 20037508.34) / 180;
        return [x, y];
      };
      const coordinates2 = degrees2meters(6.896111629585334, 46.44867318803371);

      const map = new Map({
        target: "map",
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          center: coordinates2,
          zoom: 17,
        }),
      });

      const marker = new Marker(coordinates2);
      marker.set("info", "I am a markera.");
      marker.setMap(map);

      const popup = new Popup({ offset: [0, -2] });
      map.addOverlay(popup);

      marker.on("click", function() {
        window.open("https://goo.gl/maps/ugHod1YCMgkYcZT68")
      });
    };

    mapFunction();
  }

  componentDidUpdate(prevProps) {
    // if (this.props._googleMapsLoaded && !this.state._mapInit && this.props.data.gps) {
    //     this.initMap();
    // }
  }

  render() {
    return (
      <footer>
        <Container>
          <Row>
            {/* <Col lg="5" dangerouslySetInnerHTML={{
                            __html: `<!--Zoho Campaigns Web-Optin Form Starts Here-->

<div id="sf577a6b9d619f132bb263c45a376461c9b5d32211acff08ef" data-type="signupform" style="opacity: 1;margin-bottom:20px;">
	<div id="customForm">
		<div name="SIGNUP_BODY" changeitem="BG_IMAGE" style="width: 100%; height: 400px; position: relative; font-family: Arial; background-color: rgb(214, 177, 66); margin: auto; overflow: hidden">
			<div changeitem="ELEGANTFORM_IMAGE" style="width: 100%; height: 100%; position: absolute; bottom: 0">
				<img src="https://campaign-image.com/zohocampaigns/12963d9a5_7e2pe9wjl9m.jpeg" style="width: 100%; height: 100%; position: relative; visibility: visible">
			</div>
			<div style="width: 100%; height: 400px; position: relative; font-family: &quot;Arial&quot;; margin: auto">
				<div style="position:relative;">
					<div id="Zc_SignupSuccess" style="display:none;position:absolute;margin-left:4%;width:90%;background-color: white; padding: 3px; border: 3px solid rgb(194, 225, 154);  margin-top: 10px;margin-bottom:10px;word-break:break-all ">
						<table width="100%" cellpadding="0" cellspacing="0" border="0">
							<tbody>
								<tr>
									<td width="10%">
										<img class="successicon" src="https://oyzr.maillist-manage.com/images/challangeiconenable.jpg" align="absmiddle">
									</td>
									<td>
										<span id="signupSuccessMsg" style="color: rgb(73, 140, 132); font-family: sans-serif; font-size: 14px;word-break:break-word">&nbsp;&nbsp;Thank you for Signing Up</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<form method="POST" id="zcampaignOptinForm" style="margin: 0px; width: 100%; color: rgb(255, 255, 255)" action="https://maillist-manage.com/weboptin.zc" target="_zcSignup">
					<div style="background-color: rgb(255, 235, 232); padding: 10px; color: rgb(210, 0, 0); font-size: 11px; border: 1px solid rgb(255, 217, 211); opacity: 1; position: absolute; width: 80%; margin: 20px 10%; box-shadow: rgb(27, 27, 27) 0px 5px 12px 0px; display: none; display: none" id="errorMsgDiv">Please correct the marked field(s) below.</div>
					<div style="text-align: center; width: 100%; float: left; position: relative; z-index: 2; position: absolute; bottom: 40px">
						<div>
							<div style="font-size: 22px; font-weight: normal; line-height: 1.556; margin: 0px 0px 10px; color: rgb(255, 255, 255); text-align: center; text-transform: uppercase; display: inline; width: 433px; font-family: Arial">VOUS TROUVEZ NOS CONTENUS INTÉRESSANTS</div>
							<div style="font-size: 22px; font-weight: normal; font-family: Oswald, Arial; line-height: 1.556; margin: 0px 0px 10px 5px; text-align: center; text-transform: uppercase; color: rgb(255, 255, 255); display: inline">?</div>
						</div>
						<div style="font-size: 14px; color: rgb(255, 255, 255); line-height: 1.5; width: 100%; float: left; margin: 10px 0px">INSCRIVEZ-VOUS À LA NEWSLETTER POUR FAIRE LE PLAIN D'OUTILS À EXPERIMENTER EN SOLO</div>
						<div style="text-align: center; width: 275px; height: 40px; margin: auto; margin-bottom: 20px; display: inline-block">
							<div id="Zc_SignupSuccess" style="position: absolute; width: 87%; background-color: white; padding: 3px; border: 3px solid rgb(194, 225, 154); margin-bottom: 10px; word-break: break-all; opacity: 1; display: none">
								<div style="width: 20px; padding: 5px; display: table-cell">
									<img class="successicon" src="https://campaigns.zoho.com/images/challangeiconenable.jpg" style="width: 20px">
								</div>
								<div style="display: table-cell">
									<span id="signupSuccessMsg" style="color: rgb(73, 140, 132); font-family: sans-serif; font-size: 14px; line-height: 30px; display: block"></span>
								</div>
							</div>
							<input type="text" style="border-width: 0 0 1px; border-color: rgb(99, 99, 99); border-style: solid; width: 100%; height: 100%; z-index: 4; outline: none; padding: 5px 5px; box-sizing: border-box; color: rgb(136, 136, 136); font-family: &quot;Arial&quot;; background-color: transparent" placeholder="Adresse mail" changeitem="SIGNUP_FORM_FIELD" name="CONTACT_EMAIL" id="EMBED_FORM_EMAIL_LABEL">
						</div>
						<div style="position: relative; width: 275px; height: 37px; margin-top: 0px; display: inline-block">
							<input type="submit" style="text-align: center; border-radius: 0px; background-color: rgb(213, 84, 108); width: 100%; height: 100%; z-index: 5; border: 0; color: rgb(255, 255, 255); cursor: pointer; outline: none; font-family: &quot;Arial&quot;; font-size: 14px" name="SIGNUP_SUBMIT_BUTTON" id="zcWebOptin" value="INSCRIPTION">
						</div>
					</div>
					<input type="hidden" id="fieldBorder" value="">
					<input type="hidden" id="submitType" name="submitType" value="optinCustomView">
					<input type="hidden" id="emailReportId" name="emailReportId" value="">
					<input type="hidden" id="formType" name="formType" value="QuickForm">
					<input type="hidden" name="zx" id="cmpZuid" value="12963d9a5">
					<input type="hidden" name="zcvers" value="3.0">
					<input type="hidden" name="oldListIds" id="allCheckedListIds" value="">
					<input type="hidden" id="mode" name="mode" value="OptinCreateView">
					<input type="hidden" id="zcld" name="zcld" value="1815f88a5f2d038a">
					<input type="hidden" id="document_domain" value="">
					<input type="hidden" id="zc_Url" value="oyzr.maillist-manage.com">
					<input type="hidden" id="new_optin_response_in" value="0">
					<input type="hidden" id="duplicate_optin_response_in" value="0">
					<input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW">
					<input type="hidden" id="zc_formIx" name="zc_formIx" value="577a6b9d619f132bb263c45a376461c9b5d32211acff08ef">
					<input type="hidden" id="viewFrom" value="URL_ACTION">
					<input type="hidden" id="scriptless" name="scriptless" value="yes">
				</form>
			</div>
		</div>
	</div>
</div>
<input type="hidden" id="signupFormType" value="QuickForm_Vertical">
<div id="zcOptinOverLay" oncontextmenu="return false" style="display:none;text-align: center; background-color: rgb(0, 0, 0); opacity: 0.5; z-index: 100; position: fixed; width: 100%; top: 0px; left: 0px; height: 988px;"></div>
<div id="zcOptinSuccessPopup" style="display:none;z-index: 9999;width: 800px; height: 40%;top: 84px;position: fixed; left: 26%;background-color: #FFFFFF;border-color: #E6E6E6; border-style: solid; border-width: 1px;  box-shadow: 0 1px 10px #424242;padding: 35px;">
	<span style="position: absolute;top: -16px;right:-14px;z-index:99999;cursor: pointer;" id="closeSuccess">
		<img src="https://oyzr.maillist-manage.com/images/videoclose.png">
	</span>
	<div id="zcOptinSuccessPanel"></div>
</div>

<!--Zoho Campaigns Web-Optin Form Ends Here-->`}}></Col> */}

            {/* <Col>
                            {this.props._googleMapsLoaded ?
                                <div className="map" ref={(input) => { this.GoogleMap = input; }}>

                                </div>
                                : null
                            }

                        </Col>


                        <Col lg="12">
                            <ul className="navigation">
                                {this.props.routeNames.map((item, idx) => {
                                    return (
                                        <li key={idx} onClick={() => this.props.enableScrollIntoView(true)} >
                                            <Link to={item.link}>
                                                {item.name}
                                            </Link>
                                        </li>

                                    )
                                })
                                }
                            </ul>
                        </Col> */}
            <Col lg="12" style={{ height: "300px" }}>
              <div id="map" />
            </Col>
            <Col lg="10" xs="8">
              <p className="copyright">
                Copyright © Booster Coaching - {new Date().getFullYear()}. All rights reserved.
              </p>
            </Col>
            <Col lg="2" xs="4">
                            <p className="created-by">Created by <a href="https://aimedia.ch" target="_blank">AImedia</a></p>
                        </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}
