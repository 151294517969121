import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';



export default class Button extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <button ref={(node) => this.button = node} className={`button ${this.props.additionalClassName}`} onMouseMove={(e) => {
                if (this.state.posX && this.state.posY) {
                    return;
                }
                var rect = this.button.getBoundingClientRect();
                //console.log(e.clientX - rect.left);

                this.setState({
                    posX: e.clientX - rect.left,
                    posY: e.clientY - rect.top
                })
            }}
                onMouseLeave={() => {
                    this.setState({
                        posX: null,
                        posY: null
                    })
                }}

            {...this.props}
            >
                <div className={this.state.posX && this.state.posY ? 'overlay' : null} style={{ top: this.state.posY + 'px', left: this.state.posX + 'px' }}>
                </div>
                <div className="content">
                    {this.props.children}
                </div>
            </button>

        )
    }
}
