
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';
import { fr } from 'date-fns/locale'

import Isvg from 'react-inlinesvg';
import moment from 'moment';

import calendarIcon from '../../../assets/calendar.svg';

class DateTime extends Component {
    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    
        this.state = {
        };
    }


    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
      }
    
      componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }
    
      /**
       * Set the wrapper ref
       */
      setWrapperRef(node) {
        this.wrapperRef = node;
      }
    
      /**
       * Alert if clicked on outside of element
       */
      handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
          this.setState({
              showCalendar: null
          })
        }
      }
    

    render() {


        let value = this.props.value ? this.props.value : {day: '', month: '', year: ''};
        //(value);

        return (
            <>
                {this.props.label ? <label>{this.props.label}</label> : null}
                <div className="date-time-picker-wrap" ref={this.setWrapperRef}>
                    <button type="button" onClick={() => this.setState({ showCalendar: !this.state.showCalendar })}><Isvg src={calendarIcon} /></button>
                    <div className="date-input" onClick={() => this.setState({ showCalendar: !this.state.showCalendar })}>
                        <input disabled value={value.day} onChange={(e) => {
                            let val = this.props.value;
                            if (!val){
                                val = {day: '', month: '', year: ''};
                            }
                           // console.log(e.target.value)
                            val.day = e.target.value;
                            this.props.onChange(val);
                            this.forceUpdate();
                        }} type="text" placeholder="DD" className="digit2" ref={(node) => this.day = node}
                            onKeyDown={(e) => {

                                if (("0123456789".indexOf(e.key) === -1) && e.keyCode != 8 && e.keyCode != 13) {
                                    e.preventDefault();
                                    return false;
                                }


                            }}

                            onKeyUp={(e) => {
                                if (e.target.value.length >= 2) {
                                    this.day.blur();
                                    this.month.focus();
                                }

                            }}

                        />
                        <span>.</span>
                        <input disabled value={value.month} onChange={(e) => {
                            let val = this.props.value;
                            if (!val){
                                val = {};
                            }
                            val.month = e.target.value;
                            this.props.onChange(val);
                            this.forceUpdate();
                        }} type="text" placeholder="MM" className="digit2" ref={(node) => this.month = node}
                            onKeyDown={(e) => {
                                if (("0123456789".indexOf(e.key) === -1) && e.keyCode != 8 && e.keyCode != 13) {
                                    e.preventDefault();
                                    return false;
                                }
                            }}

                            onKeyUp={(e) => {
                                if (e.target.value.length >= 2) {
                                    this.month.blur();
                                    this.year.focus();
                                } else if (e.target.value.length == 0) {
                                    this.month.blur();
                                    this.day.focus();
                                }
                            }}
                        />
                        <span>.</span>
                        <input disabled value={value.year} onChange={(e) => {
                            let val = this.props.value;
                            if (!val){
                                val = {};
                            }
                            val.year = e.target.value;
                            this.props.onChange(val);
                            this.forceUpdate();
                        }} type="text" placeholder="YYYY" className="digit4" ref={(node) => this.year = node}
                            onKeyDown={(e) => {
                                if (("0123456789".indexOf(e.key) === -1) && e.keyCode != 8 && e.keyCode != 13) {
                                    e.preventDefault();
                                    return false;
                                }
                            }}

                            onKeyUp={(e) => {

                                if (e.target.value.length >= 4) {

                                } else if (e.target.value.length == 0) {
                                    this.year.blur();
                                    this.month.focus();
                                }
                            }}

                        />
                    </div>
                 

                    {this.state.showCalendar ? <Calendar
                        minDate={new Date()}
                        locale={fr}
                        //disabledDates={[new Date()]}
                        disabledDates={this.props.busyDays ? this.props.busyDays.map((date) => {return moment.unix(date.tsFrom).toDate()}) : []}
                        date={ this.props.value && this.props.value.year && this.props.value.month && this.props.value.day ? new Date(this.props.value.year, parseInt(this.props.value.month)-1, this.props.value.day) :  new Date()}
                        onChange={(dateObject) => {
                            let day, month, year;

                            day = ('0' + dateObject.getDate()).slice(-2);
                            month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
                            year = dateObject.getFullYear();
                            this.props.onChange({
                                day,month,year
                            })
                            this.setState({
                                showCalendar: null
                            })
                        }}
                    />
                        :
                        null}

                    

                </div>
            </>


        );
    }
}

export default DateTime;