import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

import phone from '../assets/phone.svg';
import fb from '../assets/fb.svg';
import linkedin from '../assets/linkedin.svg';

import logo from '../assets/logo.svg';
import menu from '../assets/menu.svg';
import close from '../assets/close.svg';


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export default class Header extends Component {
    constructor(props) {
        super(props);

        this.nodes = [];

        this.state = {
            menu: null,
            dotPos: null
        }
    }
    componentDidMount() {

        window.addEventListener('scroll', this.listenToScroll);

        let nextIdx = this.props.routes[this.props[0].location.pathname.indexOf('/blog') !== -1 ? '/blog' : this.props[0].location.pathname];

        try {
            if(this.props[0].location.pathname.slice(0, 6) === "/page/"){
                nextIdx = 3
            } // if user visits single page then dot will be under prestations tab
            this.setState({
                dotPos: this.nodes[nextIdx].offsetLeft + this.nodes[nextIdx].offsetWidth / 2 - 4
            })
        } catch (err) { console.log(err) }

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const yScroll =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll
        })
    }

    async componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.setState({
                menu: null
            })


            let prevIdx = this.props.routes[prevProps[0].location.pathname.indexOf('/blog') !== -1 ? '/blog' : prevProps[0].location.pathname];
            let nextIdx = this.props.routes[this.props[0].location.pathname.indexOf('/blog') !== -1 ? '/blog' : this.props[0].location.pathname];

            let startPos = this.nodes[prevIdx].offsetLeft + this.nodes[prevIdx].offsetWidth / 2 - 4;
            let endPos = this.nodes[nextIdx].offsetLeft + this.nodes[nextIdx].offsetWidth / 2 - 4;
 
            if (prevIdx > nextIdx) {
                for (let i = startPos; i >= endPos; i -= 20) {
                    this.setState({
                        dotPos: i
                    })
                    await sleep(10);
                }
            } else {
                for (let i = startPos; i <= endPos; i += 20) {
                    this.setState({
                        dotPos: i
                    })

                    await sleep(10);
                }

            }

            this.setState({
                dotPos: endPos
            })


        }


    }

    render() {
        return (
            <header className={this.state.yScroll >= 100 ? 'sticky' : null}>
                <div className="header">
                    <Container>
                        <Row>
                            <Col lg="4" className="telephone">
                                <a href={`tel://${this.props.data.phone}`} target="_blank"><Isvg src={phone} /></a>
                                <span className="phone">{this.props.data.phone}</span>
                            </Col>
                            <Col lg={{ size: 4 }} xs={{ size: 8 }} className="logo">
                                <Isvg src={logo} />
                            </Col>
                            <Col lg={{ size: 4 }} className="social">
                                <span className="text">Suivez-moi</span>
                                {/* <a href="https://www.facebook.com/Booster-Coaching-113607986661962/?ref=aymt_homepage_panel&eid=ARDSVv6w-SLeRXrsZwrig2sEzu_w36_Pwxozt0IEdY03SEUjxz04ncUTcQ8jQl5KIef3ZxMqYBfPbpI5" target="_blank"><Isvg src={fb} /></a> */}
                                <a href="https://ch.linkedin.com/in/boostercoach?original_referer=https%3A%2F%2Fwww.google.com%2F" target="_blank"><Isvg src={linkedin} /></a>
                            </Col>

                            <Col xs={{ size: 4 }} className="menu">
                                <button onClick={() => this.setState({ menu: true })}>
                                    <Isvg src={menu} />
                                </button>
                            </Col>

                        </Row>
                    </Container>
                </div>
                <Container>
                    <Row>
                        <Col lg="12" className="navigation-container">

                            <ul className={this.state.menu ? `navigation navigation-mobile` : `navigation`}>
                               
                                {
                                this.props.routeNames.map((item, idx) => {
                                    // if(idx==3){
                                    //     item =  this.props.routeNames[4]
                                    // }else if(idx==4){
                                    //     item =  this.props.routeNames[3]
                                    // } // for switching prestations and temoignages

                                    if(item.link=="/quels-bénéfices"){
                                    return
                                    }

                                    return (
                                        <li ref={(node) => this.nodes[idx] = node} key={idx} onClick={() => this.props.enableScrollIntoView(true)} className={(this.props[0].location.pathname == item.link) || (item.link == '/blog' && this.props[0].location.pathname.indexOf('/blog') !== -1) ? 'active' : null}>
                                            <Link to={item.link}>
                                                {item.name}
                                            </Link>
                                        </li>

                                    )
                                })
                                }
                            </ul>


                            <div className="dot" style={{ left: this.state.dotPos + 'px' }}></div>

                            {this.state.menu ?
                                <button className="mobile-close" onClick={() => this.setState({ menu: null })}>
                                    <Isvg src={close} />

                                </button>
                                :
                                null
                            }

                            {this.state.menu ?
                                <div className="mobile-social">
                                    <a href={`tel://${this.props.data.phone}`} target="_blank"><Isvg src={phone} /></a>
                                    <a href="https://www.facebook.com/Booster-Coaching-113607986661962/?ref=aymt_homepage_panel&eid=ARDSVv6w-SLeRXrsZwrig2sEzu_w36_Pwxozt0IEdY03SEUjxz04ncUTcQ8jQl5KIef3ZxMqYBfPbpI5" target="_blank"><Isvg src={fb} /></a>
                                    <a href="https://www.linkedin.com/in/gaia-poggi-72780760/" target="_blank"><Isvg src={linkedin} /></a>

                                </div>
                                :
                                null
                            }

                        </Col>
                    </Row>
                </Container>

            </header>
        )
    }
}
