import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import DocumentMeta from 'react-document-meta';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';


import anim1 from '../assets/first.gif';
import anim2 from '../assets/2.png';
import anim3 from '../assets/meta.gif';
import anim4 from '../assets/5.png';
import anim5 from '../assets/laptop.gif';
import leftImg from '../assets/left.png';
import rightImg from '../assets/right.png';

import scroll from '../assets/scroll.svg';

import user from '../assets/user.png';
import right from '../assets/right.svg';
import Button from '../components/button';
import ReCAPTCHA from "react-google-recaptcha";

import Form from '../components/forms/form';

import 'whatwg-fetch'
import moment from 'moment';


const striptags = require('striptags');

function generateAlias(str) {
    str = str.toLowerCase();
    str = str.replace(/ä/g, 'a');
    str = str.replace(/ö/g, 'o');
    str = str.replace(/ü/g, 'u');
    str = str.replace(/ß/g, 'b');
    str = str.replace(/č/g, 'c');
    str = str.replace(/ć/g, 'c');
    str = str.replace(/ž/g, 'z');
    str = str.replace(/đ/g, 'dj');
    str = str.replace(/š/g, 's');

    str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
    str = str.replace(/-+/g, '-');

    return str;
}



class BlogPage extends Component {
    constructor(props) {
        super(props);

        this.state = { activeIndex: 0, yScroll: 0, items: [], categories: [] };
        this.contact = this.contact.bind(this);
        this.getSearchParams = this.getSearchParams.bind(this);

        this.nodes = [null, null, null, null, null, null];

    }

    getSearchParams() {
        let brokenParams = this.props[0].location.search.replace('?', '').split('&');
        let params = {};
        for (let i = 0; i < brokenParams.length; i++) {
            params[brokenParams[i].split('=')[0]] = brokenParams[i].split('=')[1];
        }

        return params;
    }


    componentDidMount() {
        fetch('https://api.boostercoaching.ch/blog', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json()).then((result) => {
            //console.log(result);
            this.setState({
                items: result
            })
        })

        fetch('https://api.boostercoaching.ch/blogCategories', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json()).then((result) => {
            //console.log(result);
            this.setState({
                categories: result
            })
        })


        window.scrollTo(0, 0);

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
        window.removeEventListener('touchmove', this.listenToScroll)

    }

    

    contact(data) {
        //console.log(data);

        fetch('https://api.boostercoaching.ch/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            //console.log(result);
            if (result.successful) {
                this.setState({
                    _contactDone: true
                })
            }
        })

    }
    render() {
        let searchParams = this.getSearchParams();
        // console.log(searchParams);

        const meta = {
            title: 'Blog | ' + this.props.seo.title,
            description: this.props.seo.description,
            meta: {
                charset: 'utf-8',
                property: {
                    keywords: this.props.seo.tags,
                    'og:url': window.location.href,
                    'og:title': 'Blog | ' + this.props.seo.title,
                    'og:image': this.props.seo.image,
                    'og:description': this.props.seo.description,
                    'keywords': this.props.seo.keywords

                }
            }
        };


        ////console.log(this.nodes);
        const { activeIndex } = this.state;

        const slides = this.props.reviews.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.title}
                >
                    <h6>TEMOIGNAGES</h6>
                    <h3>{item.name}</h3>
                    <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                </CarouselItem>
            );
        });

        return (
            <div className="page-wrap" >
                <DocumentMeta {...meta} />

                <section className="blog-section">
                    <Container>
                        <Row>
                            <Col lg={{size: 8, order: 0}} xs={{size: 12, order: 1}} className="articles">
                                {
                                    this.state.items.map((item, idx) => {
                                        if (searchParams.category == null || searchParams.category == item.category)
                                            return (
                                                <article>
                                                    <Link to={`/blog/${generateAlias(item.title)}/${item.id}`}><h2>{item.title}</h2></Link>
                                                    <div className="user">
                                                        <img src={user} />
                                                        <div>
                                                            <p className="username">Gaia Poggi</p>
                                                            <p className="date">{moment.unix(item.time).format('MMM DD, YYYY')}</p>
                                                        </div>
                                                    </div>

                                                    <Link to={`/blog/${generateAlias(item.title)}/${item.id}`}><img src={item.image} /></Link>
                                                    <div className="content" dangerouslySetInnerHTML={{ __html: item.content && striptags(item.content).length > 220 ? striptags(item.content).substring(0, 220) + '...' : striptags(item.content) }}>

                                                    </div>
                                                    <Link to={`/blog/${generateAlias(item.title)}/${item.id}`} className="read-more" >Lire la suite <Isvg src={right} /></Link>


                                                </article>

                                            )
                                    })
                                }
                            </Col>
                            <Col lg={{size: 4, order: 1}} xs={{size: 12, order: 0}} className="categories">
                                <h3>Les catégories</h3>
                                <ul>
                                    {
                                        this.state.categories.map((item, idx) => {
                                            return (
                                                <li><Link to={`/blog?category=${item.id}`}>{item.name}</Link></li>

                                            )
                                        })
                                    }
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section-6" ref={(node) => this.nodes[5] = node}>
                    <img src={rightImg} className="bg" />
                    <Container>

                        <Row>


                            <Col lg="4" md={{ size: 4 }}>
                                <h3>PRENDRE RENDEZ-VOUS</h3>
                                <h6>Adresse:</h6>
                                <p dangerouslySetInnerHTML={{ __html: this.props.data.address && this.props.data.address.replace(/\n/g, '<br/>') }}>

                                </p>
                                <h6>E-mail:</h6>
                                <p><a href={`mailto://${this.props.data.email}`}>{this.props.data.email}</a></p>
                                <h6>Téléphone:</h6>
                                <p><a href={`tel://${this.props.data.phone}`}>{this.props.data.phone}</a></p>

                            </Col>
                            <Col lg={{ offset: 2, size: 6 }} md={{ size: 8 }}>
                                <Container>
                                    <Row>
                                        <Col lg="12">
                                            <h2>CONTACTEZ-NOUS</h2>
                                        </Col>
                                    </Row>

                                    <Form onSubmit={this.contact}></Form>
                                    {this.state._contactDone ? <Row>
                                        <Col lg="12">
                                            <p>Votre message a bien été transmis</p>
                                        </Col>
                                    </Row>
                                        : null
                                    }

                                </Container>
                            </Col>

                        </Row>


                    </Container>
                </section>



            </div >
        );
    }
}

export default Page(BlogPage);