import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes'

import './App.css';
import 'whatwg-fetch'


class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.enableScrollIntoView = this.enableScrollIntoView.bind(this);

    window.googleMapsCallback = this.googleMapsCallback;

    this.state = {
      _googleMapsLoaded: false,
      _enableScrollIntoView: null,
      pages: [],
      reviews: [],
      data: {},
      seo: {},
      busyDays: [],
      routes: {},
      _routes: [],
      routeNames: []
    };

  }


  enableScrollIntoView(enableScrollIntoView, callback) {
    this.setState({
      _enableScrollIntoView: enableScrollIntoView
    }, callback)
  }

  render() {
    if (!this.state.pages.length){
      return null;
    }

    // console.log(this.state);

    return (
      <Routes
        {...this.state}
        enableScrollIntoView={this.enableScrollIntoView}
      />

    );

  }


  componentDidMount() {
    fetch('https://api.boostercoaching.ch/pages', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json()).then((result) => {

      // result = result.filter(page=>{
      //   return page.alias!=="/quels-bénéfices"
      // }) //removing "/quels-bénéfices" page from website

 
      let routes = {
        '/temoignages': 4,
        '/blog': 5,
        '/contact': 6
      }

      let _routes = [];
      let routeNames = [];

      for (let i = 0; i < result.length; i++) {
        routes[result[i].alias] = i ;
        // if (i == 3) {
        //   _routes.push('/temoignages');
        // }

        _routes.push(result[i].alias);
        // if (i == 3) {
        //   routeNames.push({
        //     name: 'TEMOIGNAGES',
        //     link: '/temoignages'
        //   }
        //   );
        // }

        routeNames.push(
          {
            name: result[i].name,
            link: result[i].alias,
            isPage: true
          }
        )

      }

      _routes.push('/temoignages');

      _routes.push('/blog');

      _routes.push('/contact');

      routeNames.push({
          name: 'TEMOIGNAGES',
            link: '/temoignages'
      });

      routeNames.push({
        name: 'BLOG',
        link: '/blog'
      });

      routeNames.push({
        name: 'CONTACT',
        link: '/contact',
      });

      this.setState({
        pages: result,
        routes,
        _routes,
        routeNames
      })


    })
    fetch('https://api.boostercoaching.ch/busyDays', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json()).then((result) => {
      //console.log(result);
      this.setState({
        busyDays: result
      })
    })

    fetch('https://api.boostercoaching.ch/reviews', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json()).then((result) => {
      //console.log(result);
      this.setState({
        reviews: result
      })
    })

    fetch('https://api.boostercoaching.ch/data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json()).then((result) => {
      //console.log(result);
      this.setState({
        data: result
      })
    })
    fetch('https://api.boostercoaching.ch/seo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json()).then((result) => {
      //console.log(result);
      this.setState({
        seo: result
      })
    })


    setTimeout(() => {
      window.prerenderReady = true;
    }, 5000);
  }

  googleMapsCallback() {
    this.setState({ _googleMapsLoaded: true });
  }

}

export default App;
