import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import Button from '../button';
import ReCAPTCHA from "react-google-recaptcha";

const required = value => value ? undefined : "Required"


class Text extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        return (
            <input type={this.props.type} placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} className={this.props.error ? 'input-field required' : 'input-field'} />
        );
    }
}
class Textarea extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <textarea placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} className={this.props.error ? 'input-field required' : 'input-field'} ></textarea>
        );
    }
}

class Captcha extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ReCAPTCHA
                sitekey="6Ld2TrcUAAAAAK6kDP_6yfWTiR4iRnpsP7iEXuMC"
                onChange={this.props.onChange}
            />
        );
    }
}

const renderTextField = ({
    input,
    placeholder,
    type,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            type={type}
            error={touched && error}

            {...input}
        />
    )

const renderTextareaField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            error={touched && error}

            {...input}
        />
    )
const renderCaptchaField = ({
    input,
    meta: { touched, error },
}) => (

        <Captcha
            {...input}
        />
    )




const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    //console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col lg="6">
                    <Field name="name" type="text" placeholder="Nom" component={renderTextField} validate={[required]}/>
                </Col>
                <Col lg="6">
                    <Field name="email" type="email" placeholder="E-mail" component={renderTextField} validate={[required]}/>

                </Col>
                <Col lg="12">
                    <Field name="subject" type="text" placeholder="Objet" component={renderTextField} validate={[required]}/>
                </Col>
                <Col lg="12">
                    <Field name="phone" type="text" placeholder="Téléphone" component={renderTextField} validate={[required]}/>
                </Col>
                <Col lg="12">
                    <Field name="message" type="text" placeholder="Message" component={renderTextareaField} validate={[required]}/>

                </Col>
                <Col lg="6">
                    <Field name="token" component={renderCaptchaField} validate={[required]}/>
                </Col>
                <Col lg="6">
                    <Button>ENVOYER</Button>
                </Col>
            </Row>
        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
